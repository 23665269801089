<template>
  <div class="serverDetail">
    <Backpage title="editServer.detail">
      <template #header>
        <el-button
          type="primary"
          @click="confirmAdd"
        >
          {{ $t("common.confirmAdd") }}
        </el-button>
        <el-button
          type="primary"
          :disabled="isOnlyIManager"
          @click="addServiceIP"
        >
          {{ $t("editServer.addServiceIP") }}
        </el-button>
      </template>
      <el-form
        ref="serverDialogForm"
        :model="serverMsg"
        label-width="150px"
        :inline="true"
        :rules="serverMsgrules"
      >
        <el-form-item
          :label="$t('editServer.serverIp')"
          prop="server_ip"
        >
          <el-input
            v-model="serverMsg.server_ip"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('common.port')"
          prop="ssh_port"
        >
          <el-input
            v-model.number="serverMsg.ssh_port"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('common.username')"
          prop="username"
        >
          <el-input
            v-model="serverMsg.username"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('common.password')"
          prop="password"
        >
          <el-input
            v-model="serverMsg.password"
            type="password"
            auto-complete="new-password"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('editServer.serverType')"
          prop="server_types"
        >
          <el-select
            v-model="serverMsg.server_types"
            :popper-append-to-body="false"
            :placeholder="$t('common.pleaseChoose')"
            style="width:177px"
            multiple
          >
            <el-option
              v-for="item in serverTypeLists"
              :key="item.id"
              :disabled="isDisabled(item.id)"
              :label="item.server_type_label"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('editServer.ndselect')"
          prop="ndselect"
          :rules="ndselectIsRequire ? [{ required: true, message: $t('common.required'), trigger: 'blur'}] : []"
        >
          <el-input
            v-model="serverMsg.ndselect"
            :disabled="isOnlyIMs"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item :label="$t('common.domain')">
          <el-input
            v-model="serverMsg.domain"
            :disabled="isOnlyIManager"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('editServer.managementPort')"
          prop="management_port"
        >
          <el-input
            v-model.number="serverMsg.management_port"
            :disabled="isOnlyIManager"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item
          :label="$t('editServer.dataPort')"
          prop="data_port"
        >
          <el-input
            v-model.number="serverMsg.data_port"
            :disabled="isOnlyIManager"
            style="width:177px"
          />
        </el-form-item>
        <el-form-item :label="$t('editServer.is_main_imanager_server')">
          <el-checkbox
            v-model="serverMsg.is_main_imanager_server"
            :disabled="isOnlyIMs"
            style="width:177px"
            :true-label="1"
            :false-label="0"
          />
        </el-form-item>
        <el-form
          v-for="service in serverMsg.service_ips"
          :key="service.id"
          :model="service"
        >
          <el-card style="margin-bottom: 10px;overflow: visible !important;">
            <div slot="header">
              <div>
                <span style="margin-right: 20px">{{ $t("editServer.serviceIP") }}</span>
                <el-button
                  type="danger"
                  size="mini"
                  @click="delService(index)"
                >
                  {{ $t("common.delete") }}
                </el-button>
              </div>
            </div>
            <el-form-item :label="$t('editServer.pushIp')">
              <el-input
                v-model="service.push_ip"
                :disabled="isOnlyIManager"
                style="width:177px"
              />
            </el-form-item>
            <el-form-item :label="$t('editServer.pullIp')">
              <el-input
                v-model="service.pull_ip"
                :disabled="isOnlyIManager"
                style="width:177px"
              />
            </el-form-item>
            <el-form-item :label="$t('editServer.region')">
              <el-select
                v-model="service.regions"
                :popper-append-to-body="false"
                multiple
              >
                <el-option
                  v-for="region in regionOptions"
                  :key="region.id"
                  :label="region.region_name"
                  :value="region.id"
                />
              </el-select>
            </el-form-item>
          </el-card>
        </el-form>
      </el-form>
    </Backpage>
  </div>
</template>

<script>
import {
  get_server_types,
  add_server,
  get_region_list
} from '@/api/server'
import Backpage from '@/components/Backpage.vue'

export default {
  components: {
    Backpage
  },
  data () {
    const validateIp = (rule, value, cb) => {
      const reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
      if (reg.test(value)) {
        cb()
      } else {
        cb(new Error(this.$t('common.ipRemind')))
      }
    }
    const validatePort = (rule, value, cb) => {
      if (value === '' || value === null || value === undefined) {
        cb()
        return
      }
      if (typeof value !== 'number') {
        cb(new Error(this.$t('form.number')))
        return
      }
      if (value <= 0 || value >= 65535) {
        cb(new Error(this.$t('form.scope') + '[1,65534]'))
        return
      }
      cb()
    }
    return {
      serverTypeLists: [],
      serverMsg: {
        server_ip: '',
        username: '',
        password: '',
        server_types: '',
        domain: '',
        ndselect: '',
        is_main_imanager_server: 0,
        ssh_port: 22,
        management_port: 8000,
        data_port: 12345,
        service_ips: []
      },
      serverMsgrules: {
        server_ip: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { validator: validateIp, trigger: 'blur' }
        ],
        username: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        password: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        server_types: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
        ssh_port: [
          { required: true, message: this.$t('common.required'), trigger: 'blur' },
          { type: 'number', message: this.$t('form.number'), trigger: 'blur' },
          { validator: validatePort, trigger: 'blur' }
        ],
        data_port: [
          { validator: validatePort, trigger: 'blur' }
        ],
        management_port: [
          { validator: validatePort, trigger: 'blur' }
        ]
      },
      serviceips: []
    }
  },
  computed: {
    ndselectIsRequire () {
      return this.serverMsg.server_types.includes(2)
    },
    isOnlyIManager () {
      return this.serverMsg.server_types.includes(2) && this.serverMsg.server_types.length == 1
    },
    isOnlyIMs () {
      return this.serverMsg.server_types.includes(1) && this.serverMsg.server_types.length == 1
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    isDisabled (id) {
      if (id == 3 && this.serverMsg.server_types.includes(1)) return true
      if (id == 1 && this.serverMsg.server_types.includes(3)) return true
    },
    // 获取区域列表
    async getRegionList () {
      const res = await get_region_list()
      if (res.data.code === 0) {
        this.regionOptions = res.data.data
      }
    },
    // 添加服务IP
    addServiceIP () {
      this.serverMsg.service_ips.push({
        pull_ip: '',
        push_ip: '',
        regions: []
      })
    },
    // 删除服务IP
    delService (index) {
      this.$confirm(this.$t('common.isConfirmDelete'), this.$t('common.remind'), {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      })
        .then(() => {
          this.serverMsg.service_ips.splice(index, 1)
        })
        .catch(() => {})
    },
    // 获取服务类型列表
    async getServerTypes () {
      const res = await get_server_types()
      if (res.data.code === 0) {
        this.serverTypeLists = res.data.data
      }
    },
    // 确认添加
    async confirmAdd () {
      const loading = this.$globalLoading()
      const res = await add_server(this.serverMsg)
      if (res.data.code === 0) {
        this.$message.success(this.$t('common.addSuccess'))
        this.$router.push('/server/index')
      }
      loading.close()
    },
    init () {
      this.getRegionList()
      this.getServerTypes()
    }
  }
}
</script>

<style lang='scss' scoped>
  .serverDetail /deep/ .el-form-item__label {
    width: 150px !important;
  }
  .desc {
    font-size: 14px;
    /* color: #606266; */
    margin-right: 10px;
    margin-left: 10px;
  }
</style>
